import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Hero from '../components/hero'
import Layout from '../components/layout'
import { Link } from 'gatsby'


import ToggleButton from '../components/toggle-button'


import ArticleListing from '../components/article-listing'


import Img from 'gatsby-image'


import ContactCollapsible from '../components/contact-collapsible'

import MetadataGenerator from '../components/metadata-generator'


import Tile from '../components/tile'

import ContentTile from '../components/content-tile'


import styles from '../components/sitemap.module.scss'

import LeadSection from '../components/lead-section'


//cannot have relative links for metadata 
import { Location } from '@reach/router'



class RootIndex extends React.Component {

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const pageTitle = 'Site Map'
    const eyebrow = siteTitle
    const subheading = 'Use the Site Map to find your way around YouView Support.'
    const siteUrl = get(this, 'props.data.site.siteMetadata.siteUrl')
    const pages = get(this, 'props.data.allContentfulSupportSitePage.edges')
    const articles = get(this, 'props.data.allContentfulSupportSiteArticle.edges')
    const supportProducts = get(this, 'props.data.allContentfulSupportSiteProduct.edges')
    //const contentTiles = get(this, 'props.data.allContentfulContentTile.edges')

    const contactSections = get(this, 'props.data.allContentfulSupportSiteContactSection.edges')

    const videos = get(this, 'props.data.allContentfulSupportSiteVideo.edges')
    const updateTables = get(this, 'props.data.allContentfulSupportSiteProductUpdateTable.edges')
    const products = get(this, 'props.data.allContentfulSupportSiteProduct.edges');
    const topics = get(this, 'props.data.allContentfulSupportSiteTopic.edges');



    const metaDescription = "Welcome to YouView support. Find out how to set up and use YouView and browse popular topics to help you with all of your YouView products."
    const headerImageTest = get(this, 'props.data.contentfulAsset');

    const excludedSlug = 'mobile-app'


    var homepageTilesObject = ''

    var homepageTilesOutput = ''

    if(this.props.data.contentfulSupportSiteOverview) {
      homepageTilesObject = this.props.data.contentfulSupportSiteOverview.homepageContentTiles;

      homepageTilesOutput = this.props.data.contentfulSupportSiteOverview.homepageContentTiles.map((node, i) => (
                 <ContentTile key={node.id} fields={node} tilePages={pages} tileArticles={articles} iterator={i}/>
                  ))
      //console.log(homepageTilesObject)
    }


    const userGuides = get(this, 'props.data.allContentfulUserGuide.edges')

    var articleArray = [];

    // articles.map((article, i) => {
    //       return articleArray.push({article})
    //     });
    
    pages.map((article, i) => {
          return articleArray.push({
            id:article.node.id,
            title:article.node.title,
            slug:article.node.slug,
            date:article.node.updatedAt,
            topicSlug:(article.node.pageTopic ? article.node.pageTopic.slug : null),
            productSlug:(article.node.pageProduct ? article.node.pageProduct.slug : null),
            description:(article.node.description ? article.node.description : null),
            articleBody:(article.node.body ? article.node.body.json : null)
        });
        });
     articles.map((article, i) => {
          return articleArray.push({
            id:article.node.id,
            title:article.node.title,
            slug:article.node.slug,
            date:article.node.updatedAt,
            topicSlug:(article.node.articleCategoryTopic ? article.node.articleCategoryTopic.slug : null),
            productSlug:(article.node.productReference ? article.node.productReference.slug : null),
            description:(article.node.description ? article.node.description : null),
            articleBody:(article.node.articleBody ? article.node.articleBody : null)
        });
      });
articleArray.sort(function(a,b){
  // Turn your strings into dates, and then subtract them
  // to get a value that is either negative, positive, or zero.
  //return new Date(b.date) - new Date(a.date);
    return a.date - b.date;

});

articleArray.reverse();

//console.log(articleArray)
      





    return (

      <Layout headerImage={headerImageTest} alertslocation={this.props.location} title={pageTitle}>
          

          <MetadataGenerator 
            title={pageTitle} 
            siteTitle={siteTitle} 
            description={subheading} 
            urlBase={siteUrl}
            url={this.props.location.href}/>

          

          <section>
          <div className="container">
          <LeadSection
          title={pageTitle} 
          eyebrow={eyebrow} 
          subheading={subheading}
 
          />
          </div>


          

         
             <div className={"container"}>
             <div className={styles.siteMapContainer}>
             <div>
               <h3> Resources </h3>
               <ul className={"list-unstyled"}>
             {pages.map(( page , i) => {
              return (
                <>
                {articleArray.map(( article , i) => {
                       if(article.id == page.node.id && article.slug != 'placeholder') {
                        let pageSlug = ''
                        if(article.productSlug !== null && article.productSlug !== ' ') {
                          pageSlug += `/${article.productSlug}`;
                        }

                        if(article.topicSlug !== null && article.topicSlug !== ' ') {
                          pageSlug += `/${article.topicSlug}`;
                        }
                        
                        if(article.slug) {
                          pageSlug += `/${article.slug}/`;
                        }
                        let title = ''
                        let titleArray = article.title.split(" ");
                        if(titleArray.length > 4) {
                          for (var i = 0; i < titleArray.length; i++) {
                            let spacer = " "
                            if(i == titleArray.length-2){
                              spacer = "\u00A0"
                            }
                            else {
                              spacer = " " 
                            }
                            title += titleArray[i]+spacer
                          }  
                        }
                        else {
                          title = article.title
                        }
                        if(article.productSlug != excludedSlug && article.topicSlug !== 'software-updates') {
                          return (
                             <li id={i + 1} key={article.slug}>
                             <Link to={pageSlug}>{title}</Link>
                              
                              </li>
                          ) 
                        }

                        
                       }
                      })}</>)
             })}
             </ul>
             </div>
             </div>
             {products.map(( product , i) => {
              if(product.node.slug != excludedSlug){
               return (
                <div key={product.node.id} className={styles.siteMapContainer}>
                  <h2 id={i + 1}> {product.node.title} </h2>
                  <br/>                  
                  {product.node.homepageCategoryOrder.map(( topic , i) => {
                    
                    if(topic.slug !== 'software-updates'){
                    return (
                      <React.Fragment>
                      <div>
                      <h3 key={topic.title}>{topic.title}</h3>
                      <ul className={"list-unstyled"}>
                      {articleArray.map(( article , i) => {
                       if(article.productSlug == product.node.slug && article.topicSlug == topic.slug) {
                        let pageSlug = ''
                        if(article.productSlug !== ' ') {
                          pageSlug += `/${article.productSlug}`;
                        }

                        if(article.topicSlug !== ' ') {
                          pageSlug += `/${article.topicSlug}`;
                        }
                        
                        if(article.slug) {
                          pageSlug += `/${article.slug}/`;
                        }
                        let title = ''
                        let titleArray = article.title.split(" ");
                        if(titleArray.length > 4) {
                          for (var i = 0; i < titleArray.length; i++) {
                            let spacer = " "
                            if(i == titleArray.length-2){
                              spacer = "\u00A0"
                            }
                            else {
                              spacer = " " 
                            }
                            title += titleArray[i]+spacer
                          }  
                        }
                        else {
                          title = article.title
                        }
                        if(article.productSlug != excludedSlug && article.topicSlug !== 'software-updates') {

                        return (
                           <li id={i + 1} key={article.slug}>
                           <Link to={pageSlug}>{title}</Link>
                            
                            </li>
                        )
                      }
                       }
                      })}
                       </ul>
                   
                   </div>
                   </React.Fragment>
                      )
                    }
                    
                  })}
                  
                 
                 
                  </div>
               )
             }
             })}
             </div>

             </section>
            
        
      </Layout>

    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query siteMapQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    
  #start Page query
  allContentfulSupportSitePage(
    filter: {
      slug: {ne: "placeholder"}
    }
  ) {
    edges {
      node {
        title
        slug
        id
        createdAt (formatString: "X")
        updatedAt (formatString: "X")
        pageTopic {
          ... on ContentfulSupportSiteTopic {
            slug
          }
        }
        pageProduct {
          ... on ContentfulSupportSiteProduct {
            slug
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        body {
          json
        }
      }
    }
  }
  #end Page query

    contentfulSupportSiteOverview(title:{eq:"Overview"}) {
      id
    title
    homepageContentTiles {
      ... on ContentfulSupportSiteContentTile {
        id
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        topic {
          title
          slug
        }
        url
        icon {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        tileArticles {
          ... on ContentfulSupportSiteArticle {
            title
            slug
            articleCategoryTopic {
              slug
            }
            productReference {
              slug
            }
          }
          ... on ContentfulSupportSitePage {
            title
            slug
            pageTopic {
              slug
            }
            pageProduct {
              slug
            }
          }
        }
      }
    }
  }

    allContentfulSupportSiteArticle(sort: { fields: [updatedAt], order: DESC }) {
      edges {
        node {
          title
          slug
          id
          createdAt (formatString: "X")
          updatedAt (formatString: "X")
          description {
            childMarkdownRemark {
              html
            }
          }
          articleBody {
            json 
            content {
              content {
                value
              }
            }
          }
             productReference {
            ... on ContentfulSupportSiteProduct {
              title
              slug
            }
          }
             articleCategoryTopic {
            ... on ContentfulSupportSiteTopic {
              title
              slug
            }
          }
        }
      }
    }
    allContentfulSupportSiteTopic {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
 #start product search
 allContentfulSupportSiteProduct(sort: { fields: [createdAt], order: ASC }) {
  edges {
    node {
      title
      slug
      id
      image {
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
      homepageCategoryOrder {
        ... on ContentfulSupportSiteTopic {
          title
          slug
          icon {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
}
  #end product search
  contentfulAsset(file: {fileName: {eq: "youview-support-background.jpg"}}, fluid: {}) {
    fixed(width: 1800, quality: 100) {
      base64
      aspectRatio
      width
      height
      src
      srcSet
      srcWebp
      srcSetWebp
    }
  }
  }
`
