import React from 'react'
import { Link } from 'gatsby'

import styles from './tile.module.scss'

import ReactDOM from "react-dom";






class ToggleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {addClass: false}
  }
  toggle() {
    this.setState({addClass: !this.state.addClass});
  }
  render() {
    let boxClass = ["btn btn-primary"];
    if(this.state.addClass) {
      boxClass.push('green');
    }

    const node = ReactDOM.findDOMNode(this);

    // Get child nodes
    if (node instanceof HTMLElement) {
        const child = node.querySelector(styles.productPanel+':first-child');
        console.log('child is '+child)
    }
    return(
      
        <div className={boxClass.join(' ')} onClick={this.toggle.bind(this)}>{this.state.addClass ? "Remove a class" : "Add a class (click the box)"}<br /></div>       
    );
  }
}

export default ToggleButton

