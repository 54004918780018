import React from 'react'
import { Link } from 'gatsby'

import styles from './tile.module.scss'


class Tile extends React.Component {
  render() {
    const article = this.props.article;
    const iterator = this.props.iterator;
    const product = this.props.product;

    var description = this.props.description;

    var link = this.props.url;


    const allTileArticles = this.props.tileArticles;
var tileArticles = ''

    if(this.props.tileArticles) {
      var articles = this.props.tileArticles;
      var categorySlug = this.props.article.slug;
      var productSlug = this.props.product;
      var index = 0;

      var descriptionHTML = '';

      tileArticles = articles.map((listArticle, i) => {
        if(listArticle.node.productReference.slug == productSlug && listArticle.node.articleCategoryTopic.slug == categorySlug && index < 5) {
          index++
          return <Link key={listArticle.node.id} to={`/${listArticle.node.productReference.slug}/${listArticle.node.articleCategoryTopic.slug}/${listArticle.node.slug}/`}>{listArticle.node.title}</Link>
        }
      }
    )}

    const title = this.props.title;

    var icon = null
    var seeAllLink = null

    if(article) {
      icon = article.icon;
       seeAllLink = <p className={styles.seeAllButton}><Link className={styles.seeAll} to={`/${product}/${article.slug}/`}>See all</Link></p>
    }

    if(description && link){
      if(link.includes('http')){
        descriptionHTML = <a href={this.props.url}>{description}</a>

      }
      else {
        descriptionHTML = <Link to={this.props.url} >{description}</Link>

      }
    }



    var tileClass = styles.tile
    var tileTitle = '';
    var tileTitleIcon = '';

    if(title) {
      tileTitle = title;
    }
    else if(!title && article) {
      tileTitle = article.title
    }


    if(article && icon && iterator < 6) {
      tileClass = [styles.tile, styles.hiddenArticles].join(' ')
      tileTitleIcon = <p className={[styles.topicTitle, styles.animated].join(' ')}>{tileTitle}</p>
    }
    else if(article && icon && iterator >= 6) {
      tileClass = [styles.tile, styles.titleOnly].join(' ')
      tileTitle = <Link to={`/${product}/${article.slug}/`}>{tileTitle}</Link>
      tileArticles = '';


    }
    if(!article && description) {
      tileClass = [styles.tile, styles.singleLink].join(' ')
    }

    var iconStatus = ' '
    if(icon) {
      iconStatus = <div className={styles.largeIcon}><img width={article.icon.file.details.image.width} height={article.icon.file.details.image.height} src={article.icon.file.url} /></div>
    }
    //const productCategories = this.props.product.homepageCategoryOrder;

    //console.log(article)

    // const post = get(this.props, 'data.contentfulArticle')
    // const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
    	 <div className={tileClass}>
    	 {iconStatus}
      <p className={styles.topicTitle}>{tileTitle}</p>
      {tileTitleIcon}

      <div className={styles.topics}>
      {descriptionHTML}

      {tileArticles}
      </div>
      {seeAllLink}
      
    </div>
    )
}
}
export default Tile


