import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types';

import ContactRow from '../components/contact-row'


//import Tile from '../components/tile'

import styles from './contact.module.scss'

import {newCollapsible} from './article.module.scss'

import ReactCollapsible from 'react-collapsible';






class ContactCollapsible extends React.Component {
  render() {
    const contentType = this.props.contactCategory;
    const contactList = this.props.contactCategory.contacts;
    const CollapsibleIndex = this.props.index;

    var description = '';

    var webite = '';

    if(this.props.contactCategory.website) {
      website = contact.website.childMarkdownRemark.html;
    }

    if (!this.props.contactCategory.description) {
      description = '';
    }
    else {
      description = <div className={"panel-body"} dangerouslySetInnerHTML={{
                        __html: contentType.description.childMarkdownRemark.html,
                      }}
                      />

    }
    //console.log( this.props.contactCategory)
    
    const logOpen = (index, id) => {

    let element = document.getElementById(id);
    element.scrollIntoView({behavior: 'smooth', block: 'start'});

    };
    let stepTitle = <><div className={"button is-icon-only accordion-toggle-cta-small"}><div className={"icon-small toggle_small w-embed"}><svg width="2" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M.447 16.932v-16h1v16h-1Z" fill="currentColor"></path></svg></div><div className={"icon-small is-overlapped toggle_small w-embed"} ><svg width="2" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M.447 16.932v-16h1v16h-1Z" fill="currentColor"></path></svg></div></div><h2>{contentType.title}</h2></>
 let triggerProps = {id: this.props.contactCategory.id}
 
    return (
      <>
      <div className={styles.panel}>
      <ReactCollapsible classParentString={newCollapsible} accordionPosition={this.props.index} trigger={stepTitle} easing={'cubic-bezier(.25,.1,.25,1)'} triggerTagName={'a'} triggerElementProps={triggerProps} triggerClassName={newCollapsible.isClosed} triggerOpenedClassName={newCollapsible.isOpen} onOpen={() => logOpen(CollapsibleIndex,this.props.contactCategory.id)}>
       
     
     {description}
     <table className={[styles.contactTable, 'table'].join(' ')}>
     <tbody>
     {contactList.map((contact, i) => (
       <ContactRow contact={contact} key={contact.id}/>
     ))}
     </tbody>
     </table>
       </ReactCollapsible> 
      </div>

      </>
    )
  }
}

export default ContactCollapsible