import React from 'react'
import { Link } from 'gatsby'

import styles from './tile.module.scss'


class ContentTile extends React.Component {
  render() {
    const fields = this.props.fields;
    const article = this.props.article;
    const iterator = this.props.iterator;
    var description = false;

    var link = false;

    var title = ''
    var topicSlug = ''
    var descriptionHTML = ''
    var tileIcon = '';

    var product = false

    if(this.props.product){
      product = this.props.product
    }

    if(fields.topic) {
      title = fields.topic.title
      topicSlug = fields.topic.slug;
    }

    if(fields.title) {
      title = fields.title
    }

    if(fields.description){
      description = fields.description.childMarkdownRemark.html
    }
    if(fields.url) {
      link = fields.url
    }

    var excludedSlug = 'mobile-app';

    //check for icon

    
    
    var specificTileArticles = false

   const allTileArticles = this.props.tileArticles;
   const allTilePages = this.props.tilePages;

    if(fields.tileArticles) {
      specificTileArticles = fields.tileArticles;
    }


    var tileArticles = ''

    if(specificTileArticles !== false){
      tileArticles = specificTileArticles.map((listArticle, i) => {
        let topicSlug = '';
        let productSlug = '';
        let pageLink = ``;
        if(listArticle.productReference) {
          productSlug = listArticle.productReference.slug;
          pageLink += `/${productSlug}`
        }
        if(listArticle.pageProduct) {
          productSlug = listArticle.pageProduct.slug;
          pageLink += `/${productSlug}`
        }
        if(listArticle.articleCategoryTopic) {
          topicSlug = listArticle.articleCategoryTopic.slug;
          pageLink += `/${topicSlug}`
        }
        if(listArticle.pageCategory) {
          topicSlug = listArticle.pageCategory.slug;
          pageLink += `/${topicSlug}`
        }
        pageLink += `/${listArticle.slug}/`;
        return <Link key={listArticle.id} to={pageLink} > {listArticle.title} </Link>
      })
    }
    if(specificTileArticles == false) {
      var allArticles = this.props.tileArticles;

      if(fields.topic) {
         topicSlug = fields.topic.slug;
      }
      else {
        topicSlug = false;
      }
      if(this.props.product) {
        var productSlug  = this.props.product;
      }
      else {
        var productSlug = false;
      }



      var index = 0;

      if(!product) {
          tileArticles = allTileArticles.map((listArticle, i) => {
            if(listArticle.node.articleCategoryTopic.slug == topicSlug && listArticle.node.productReference.slug != excludedSlug && index < 5) {
              index++
              return <Link key={listArticle.node.id} to={`/${listArticle.node.productReference.slug}/${listArticle.node.articleCategoryTopic.slug}/${listArticle.node.slug}/`}>{listArticle.node.title}</Link>
            }
          }
        )}
        else {
        tileArticles = allTileArticles.map((listArticle, i) => {
        if(listArticle.node.productReference.slug == product && listArticle.node.articleCategoryTopic.slug == topicSlug && index < 5) {
          index++
          return <Link key={listArticle.node.id} to={`/${listArticle.node.productReference.slug}/${listArticle.node.articleCategoryTopic.slug}/${listArticle.node.slug}/`}>{listArticle.node.title}</Link>
        }
      }
    )}      

}
      


    var icon = null
    var seeAllLink = null
    var iconStatus = ' '

    if(article) {
      icon = article.icon;
       seeAllLink = <p className={styles.seeAllButton}><Link className={styles.seeAll} to={`/${product}/${article.slug}/`}>See all</Link></p>
    }
    if(fields && product && iterator <= 5) {
      icon = fields.topic.icon;
       seeAllLink = <p className={styles.seeAllButton}><Link className={styles.seeAll} to={`/${product}/${topicSlug}/`}>See all</Link></p>
    }

    if(description && link){
      if(link.includes('http')){
        descriptionHTML = <a href={link} dangerouslySetInnerHTML={{
                           __html: description,
                         }}
                         />

      }
      else {
        descriptionHTML = <Link to={link} dangerouslySetInnerHTML={{
                           __html: description,
                         }}
                         />

      }

    }

    if(fields.icon) {
      tileIcon = <div className={styles.largeIcon}><img width={fields.icon.file.details.image.width} height={fields.icon.file.details.image.height} src={fields.icon.file.url} /></div>
    }



    var tileClass = styles.tile
    var tileTitle = '';

    if(title) {
      tileTitle = title;
    }
    else if(!title && article) {
      tileTitle = article.title
    }


    if(product && icon && iterator < 6) {
      tileClass = [styles.tile, styles.hiddenArticles].join(' ')
    }
    else if (product && iterator >= 6){
      tileClass = [styles.tile, styles.titleOnly].join(' ');
      tileTitle = <Link to={`/${product}/${topicSlug}/`}>{tileTitle}</Link>

    }
    if(product && icon && iterator >= 6) {
      tileClass = [styles.tile, styles.titleOnly].join(' ')
      tileTitle = <Link key={tileTitle} to={`/${product}/${fields.topic.slug}/`}>{tileTitle}</Link>

    }
    if(!article && description) {
      tileClass = [styles.tile, styles.singleLink].join(' ')
    }
    if(!product && specificTileArticles != false) {
      tileClass = [styles.tile, styles.linksOnly].join(' ')
    }

    
    if(icon) {
      iconStatus = <div className={styles.largeIcon}><img width={fields.topic.icon.file.details.image.width} height={fields.topic.icon.file.details.image.height} src={fields.topic.icon.file.url} /></div>
    }
    //const productCategories = this.props.product.homepageCategoryOrder;

    //console.log(article)

    // const post = get(this.props, 'data.contentfulArticle')
    // const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
    	 <div className={tileClass}>
    	 {iconStatus}
      <p className={styles.topicTitle}>{tileTitle}</p>

      <div className={styles.topics}>
      {descriptionHTML}

      {tileArticles}
      </div>
      {tileIcon}

      {seeAllLink}
      
    </div>
    )
}
}
export default ContentTile


