import React from 'react'
import { Link } from 'gatsby'


class ContactRow extends React.Component {
  render(){
	  const contact = this.props.contact
	// const embedLink = this.props.prependSrc + getId(this.props.id) + this.props.appendSrc
	// const videoId = getId(this.props.id); 
	return (
	  <tr key={contact.id} ><td><img alt={contact.title} width={contact.logo ? contact.logo.file.details.image.width : null} height={contact.logo ? contact.logo.file.details.image.height : null} src={contact.logo ? contact.logo.file.url : null} /></td>
	  <td dangerouslySetInnerHTML={{
		   __html: contact.number.childMarkdownRemark.html,
		 }} />
		 <td dangerouslySetInnerHTML={{
		  __html: contact.lineDetails.childMarkdownRemark.html,
		}} />
		 <td dangerouslySetInnerHTML={{
		  __html: contact.website ? contact.website.childMarkdownRemark.html : null,
		}} /></tr>
	  
	)
  }
}

ContactRow.defaultProps = {
  aspectRatio: `56.25%`,
  prependSrc: `https://www.youtube.com/embed/`,
  appendSrc: ``,
}

export default ContactRow